<template>
  <!-- FOOTER & OVERLAYS & SCRIPTS -->
  <footer>
    <ul>
      <li>&copy; {{ currentYear }} Chevron Corporation</li>
      <li>Company Confidential</li>
      <li>OpCo: <a href="http://www.lagnr.chevron.com/">NMA SBU</a></li>
      <li>
        <router-link to="/app-doc">User Guide</router-link>
      </li>
    </ul>
    <ul>
      <li>Content contact: <a href="mailto:nmaappsdev@chevron.com;l9lek652@chevron.com?subject=CMMS%20Content%20Inquiry">NMA Support Team</a></li>
      <li>Technical contact: <a href="mailto:PSCM_APPLICATION_SUPPORT@chevron.com?subject=CMMS%20Technical%20Inquiry">Application Production and Support Team</a></li>
      <li>Terms Of Use: <a href="https://www.chevron.com/terms-of-use">Terms and conditions</a></li>
    </ul>
    <ul>
      <li>This application works best on Google Chrome and Microsoft Edge</li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data () {
    return {
      currentYear: ''
    }
  },
  mounted () {
    const currentDate = new Date()
    this.currentYear = currentDate.getFullYear()
  }
}
</script>

<style scoped lang="scss">
</style>
